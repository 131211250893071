import React, { useState, createContext } from 'react';

const LoadingAuthContext = createContext();

const LoadingAuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = () => {
    if (isLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  };

  return (
    <LoadingAuthContext.Provider
      value={{
        isLoading,
        toggleIsLoading,
      }}
    >
      {children}
    </LoadingAuthContext.Provider>
  );
};

export { LoadingAuthProvider, LoadingAuthContext };
