// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// export default function ScrollToTop() {
// 	const { pathname } = useLocation();
// 	// const handleScroll = !pathname.includes('news/')

// 	// console.log(handleScroll, 'handleScroll')
// 	useEffect(() => {
// 		window.scrollTo({ top: 0, behavior: 'smooth' });
// 	}, [pathname]);

// 	return null;
// }

import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import './scrollToTop.scss';
import { useLocation } from 'react-router-dom';
const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const nodeRef = useRef(null);
    const duration = 300;
    const location = useLocation();

    useEffect(() => {
        // Обработка прокрутки к нужному блоку при изменении URL
        const hash = location.hash;
        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]);
    const toggleVisibility = () => {
        if (window.pageYOffset > 800) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <CSSTransition
            nodeRef={nodeRef}
            // key={id}
            // timeout={{ enter: duration, exit: duration }}
            in={isVisible}
            timeout={duration}
            unmountOnExit
            // unmountOnEnter
            // onEnter={() => setShowTrigger(false)}
            // onExited={() => setShowTrigger(true)}
            classNames="scroll-to-top"
        >
            <button
                ref={nodeRef}
                className="scroll-to-top"
                onClick={scrollToTop}
            >
                <span></span>
            </button>
        </CSSTransition>
    );
};

export default ScrollToTop;
