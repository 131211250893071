import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiDiagrams = createApi({
  reducerPath: 'apiDiagrams',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.isc-sai.org' }),
  tagTypes: ['Diagrams'],
  endpoints: (builder) => ({
    getDiagrams: builder.query({
      query: () => '/diagrams',
      providesTags: ['Diagrams'],
    }),
    getOneDiagrams: builder.query({
      query: (id) => `/diagrams/${id}`,
      providesTags: ['Diagrams'],
    }),
    getOneRowDiagrams: builder.query({
      query: (item) => {
        const { idDiagram, idRow } = item;
        return `/diagrams/${idDiagram}/table/${idRow}`;
      },
      providesTags: ['Diagrams'],
    }),
    updateOneRowDiagrams: builder.mutation({
      query: (item) => {
        const { idDiagram, idRow, token, body } = item;
        return {
          url: `/diagrams/${idDiagram}/table/${idRow}`,
          method: 'PUT',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['Diagrams'],
    }),

    createDiagrams: builder.mutation({
      query: (item) => {
        const { token, body } = item;

        return {
          url: '/diagrams',
          method: 'POST',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['Diagrams'],
    }),
    createOneRowDiagrams: builder.mutation({
      query: (item) => {
        const { id, token, body } = item;
        return {
          url: `/diagrams/${id}/table`,
          method: 'POST',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['Diagrams'],
    }),
    updateDiagram: builder.mutation({
      query: (item) => {
        const { token, id, body } = item;
        return {
          url: `/diagrams/${id}`,
          method: 'PUT',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['Diagrams'],
    }),
    deleteDiagrams: builder.mutation({
      query: (id) => ({
        url: `/diagrams/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Diagrams'],
    }),
    deleteOneRowDiagrams: builder.mutation({
      query: (item) => {
        const { idTable, idRow, token } = item;
        return {
          url: `/diagrams/${idTable}/table/${idRow}`,
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Diagrams'],
    }),
  }),
});

export const {
  useGetDiagramsQuery,
  useGetOneDiagramsQuery,
  useCreateDiagramsMutation,
  useDeleteDiagramsMutation,
  useUpdateDiagramMutation,
  useUpdateOneRowDiagramsMutation,
  useGetOneRowDiagramsQuery,
  useCreateOneRowDiagramsMutation,
  useDeleteOneRowDiagramsMutation,
} = apiDiagrams;
