import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiEvent = createApi({
  reducerPath: 'apiEvent',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.isc-sai.org/event' }),
  tagTypes: ['Event', 'Timer'],
  endpoints: (builder) => ({
    getHeading: builder.query({
      query: () => '/heading',
      providesTags: ['Event'],
    }),
    getOneHeading: builder.query({
      query: (id) => `/heading/${id}`,
      providesTags: ['Event'],
    }),
    createHeading: builder.mutation({
      query: (heading) => ({
        url: '/heading',
        method: 'POST',
        body: heading,
      }),
      invalidatesTags: ['Event'],
    }),
    updateHeading: builder.mutation({
      query: (heading) => {
        const { token, body } = heading;
        return {
          url: `/heading`,
          method: 'PUT',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['Event'],
    }),
    deleteHeading: builder.mutation({
      query: (id) => ({
        url: `/heading/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Event'],
    }),

    getTimer: builder.query({
      query: () => '/timer',
      providesTags: ['Timer'],
    }),
    getOneTimer: builder.query({
      query: (id) => `/timer/${id}`,
      providesTags: ['Timer'],
    }),
    createTimer: builder.mutation({
      query: (timer) => ({
        url: '/timer',
        method: 'POST',
        body: timer,
      }),
      invalidatesTags: ['Timer'],
    }),
    updateTimer: builder.mutation({
      query: (timer) => {
        const { token, body } = timer;
        return {
          url: `/timer`,
          method: 'PUT',
          headers: {
            auth: `Bearer ${JSON.parse(token)}`,
          },
          body: body,
        };
      },
      invalidatesTags: ['Timer'],
    }),
    deleteTimer: builder.mutation({
      query: (id) => ({
        url: `/timer/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Timer'],
    }),
  }),
});

export const {
  useGetHeadingQuery,
  useGetOneHeadingQuery,
  useGetOneTimerQuery,
  useGetTimerQuery,
  useCreateHeadingMutation,
  useCreateTimerMutation,
  useDeleteHeadingMutation,
  useDeleteTimerMutation,
  useUpdateHeadingMutation,
  useUpdateTimerMutation,
} = apiEvent;
