import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Menu from '../components/admin/UI/menu/Menu';
import { IconSprite } from '../components/common/iconSprite/IconSprite';
import { useEffect, Fragment } from 'react';
import { Modal, Spinner } from '../components';
import { Suspense } from 'react';

const AdminLayout = () => {
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    console.log(location, 'location');
    if (location.pathname === '/admin') {
      navigate('diagrams');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <IconSprite />
      <Menu />
      <Suspense
        fallback={
          <div
            style={{
              display: 'grid',
              minHeight: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <>
              <Spinner />
            </>
          </div>
        }
      >
        <div className="page-admin">
          <Outlet />
          <Modal />
        </div>
      </Suspense>
    </>
  );
};

export default AdminLayout;
