import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiLogin = createApi({
	reducerPath: 'apiLogin',
	baseQuery: fetchBaseQuery({ baseUrl: 'https://api.isc-sai.org' }),
	tagTypes: ['Login'],
	endpoints: builder => ({
		authLogin: builder.mutation({
			query: (user) => ({
				url: '/login',
				method: 'POST',
				body: { ...user },
			}),
			invalidatesTags: ['Login']
		}),
	})
});


export const { useAuthLoginMutation, } = apiLogin;