import { Link } from 'react-router-dom';
// import HeaderMenu from '../headerMenu/HeaderMenu'
import { HeaderMenu } from '../../index';
import './header.scss';
import logo from '../../../assets/images/logo.svg';
import { useEffect } from 'react';
const Header = () => {
    useEffect(() => {
        // addWindowScrollEvent = true;
        const header = document.querySelector('header.header');
        const headerShow = header.hasAttribute('data-scroll-show');
        const headerShowTimer = header.dataset.scrollShow
            ? header.dataset.scrollShow
            : 500;
        const startPoint = header.dataset.scroll ? header.dataset.scroll : 1;
        let scrollDirection = 0;
        let timer;
        const scrollHeader = (e) => {
            const scrollTop = window.scrollY;
            clearTimeout(timer);
            if (scrollTop >= startPoint) {
                if (!header.classList.contains('_header-scroll')) {
                    header.classList.add('_header-scroll');
                }
                if (headerShow) {
                    if (scrollTop > scrollDirection) {
                        // downscroll code
                        if (header.classList.contains('_header-show')) {
                            header.classList.remove('_header-show');
                        }
                    } else {
                        // upscroll code
                        if (!header.classList.contains('_header-show')) {
                            header.classList.add('_header-show');
                        }
                    }
                    timer = setTimeout(() => {
                        if (!header.classList.contains('_header-show')) {
                            header.classList.add('_header-show');
                        }
                    }, headerShowTimer);
                }
            } else {
                if (header.classList.contains('_header-scroll')) {
                    header.classList.remove('_header-scroll');
                }
                if (headerShow) {
                    if (header.classList.contains('_header-show')) {
                        header.classList.remove('_header-show');
                    }
                }
            }
            scrollDirection = scrollTop <= 0 ? 0 : scrollTop;
        };
        document.addEventListener('scroll', scrollHeader);
        return () => {
            document.removeEventListener('scroll', scrollHeader);
        };
    }, []);

    return (
        <header className="header">
            <div className="header__content">
                <Link to="/" className="header__logo">
                    <img src={logo} alt="ISC-SAI" />
                </Link>
                <HeaderMenu />
            </div>
        </header>
    );
};

export default Header;
