import './spinner.scss'

function Spinner() {
	return (
		<div className="spinner">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="64"
				height="64"
				version="1"
				viewBox="0 0 128 128"
				className="spinner__svg"
			>
				<g>
					<path
						fill="#119da4"
						d="M97.63 8.23a7.38 7.38 0 012.7 10.07L89.2 37.57a7.38 7.38 0 11-12.77-7.37l11.12-19.27a7.38 7.38 0 0110.08-2.7z"
					></path>
					<path
						fill="#e7f5f6"
						fillOpacity="0.1"
						d="M121.01 32.517a7.38 7.38 0 01-2.697 10.07L99.039 53.712a7.38 7.38 0 11-7.374-12.768l19.265-11.128a7.38 7.38 0 0110.08 2.702zM129.113 65.24a7.38 7.38 0 01-7.37 7.373l-22.254-.004a7.38 7.38 0 11-.002-14.744l22.248-.005a7.38 7.38 0 017.378 7.38zM119.77 97.63a7.38 7.38 0 01-10.07 2.7L90.43 89.2a7.38 7.38 0 117.37-12.77l19.27 11.12a7.38 7.38 0 012.7 10.08z"
					></path>
					<path
						fill="#d1ecee"
						fillOpacity="0.19"
						d="M95.483 121.01a7.38 7.38 0 01-10.07-2.697L74.288 99.039a7.38 7.38 0 1112.768-7.374l11.128 19.265a7.38 7.38 0 01-2.702 10.08z"
					></path>
					<path
						fill="#bce3e5"
						fillOpacity="0.28"
						d="M62.76 129.113a7.38 7.38 0 01-7.373-7.37l.004-22.254a7.38 7.38 0 1114.744-.002l.005 22.248a7.38 7.38 0 01-7.38 7.378z"
					></path>
					<path
						fill="#a5dadd"
						fillOpacity="0.38"
						d="M30.37 119.77a7.38 7.38 0 01-2.7-10.07L38.8 90.43a7.38 7.38 0 1112.77 7.37l-11.12 19.27a7.38 7.38 0 01-10.08 2.7z"
					></path>
					<path
						fill="#91d2d5"
						fillOpacity="0.46"
						d="M6.99 95.483a7.38 7.38 0 012.697-10.07l19.274-11.124a7.38 7.38 0 117.374 12.768L17.07 98.185a7.38 7.38 0 01-10.08-2.702z"
					></path>
					<path
						fill="#7bc9cd"
						fillOpacity="0.55"
						d="M-1.113 62.76a7.38 7.38 0 017.37-7.373l22.254.004a7.38 7.38 0 11.002 14.744l-22.248.005a7.38 7.38 0 01-7.378-7.38z"
					></path>
					<path
						fill="#67c0c5"
						fillOpacity="0.64"
						d="M8.23 30.37a7.38 7.38 0 0110.07-2.7L37.57 38.8a7.38 7.38 0 11-7.37 12.77L10.93 40.45a7.38 7.38 0 01-2.7-10.08z"
					></path>
					<path
						fill="#50b7bc"
						fillOpacity="0.73"
						d="M32.517 6.99a7.38 7.38 0 0110.07 2.697l11.124 19.274a7.38 7.38 0 11-12.768 7.374L29.815 17.07a7.38 7.38 0 012.702-10.08z"
					></path>
					<path
						fill="#3cafb4"
						fillOpacity="0.82"
						d="M65.24-1.113a7.38 7.38 0 017.373 7.37l-.004 22.254a7.38 7.38 0 11-14.744.002L57.86 6.265a7.38 7.38 0 017.38-7.378z"
					></path>
					<animateTransform
						attributeName="transform"
						calcMode="discrete"
						dur="720ms"
						repeatCount="indefinite"
						type="rotate"
						values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64"
					></animateTransform>
				</g>
			</svg>
		</div>
	);
}

export default Spinner;