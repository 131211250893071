import { Link, useLocation, useNavigate } from 'react-router-dom';
import classes from './menu.module.scss';
import logo from './logo.png';
import { Icon } from '../../../common/iconSprite/IconSprite';
import React from 'react';
import { menuLinks } from '../../../../routes/routes';
import Links from './Links';

const Menu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toggleMenu = () => {
    document.documentElement.classList.toggle(classes.menuOpen);
  };
  const handleLogout = () => {
    localStorage.removeItem('isc-admin-token');
    navigate('/login');
  };
  return (
    <aside className={classes.asideMenu}>
      <div className={classes.asideMenuBody}>
        <button
          type="button"
          onClick={toggleMenu}
          className={classes.iconAsideMenu}
        >
          <Icon name="arrow-right" />
        </button>
        <div className={classes.menu}>
          <header className={classes.menuHeader}>
            <Link to="/admin/diagrams" className={classes.menuLogoIbg}>
              <img src={logo} alt="Logo" />
            </Link>
            <div className={classes.userHeader}>
              <p className={classes.userHeaderUsername}>Admin</p>
              <button
                onClick={handleLogout}
                type="button"
                className={classes.userHeaderLogout}
              >
                <span>Log Out</span>
                <Icon name="logout" />
              </button>
            </div>
          </header>
          <nav className={classes.menuNav}>
            <ul className={classes.menuBody}>
              {Object.keys(menuLinks).map((key, i) => (
                <Links
                  key={i}
                  arr={menuLinks[key]}
                  title={key}
                  pathname={pathname}
                />
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};
export default Menu;
