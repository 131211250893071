import React from 'react';

const IconSprite = () => (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol id="icon-time" viewBox="0 0 20 20">
            <path
                opacity="0.4"
                d="M10 20C4.47656 20 0 15.5234 0 10C0 4.47656 4.47656 0 10 0C15.5234 0 20 4.47656 20 10C20 15.5234 15.5234 20 10 20ZM9.0625 10C9.0625 10.3125 9.21875 10.6055 9.48047 10.7461L13.2305 13.2461C13.6602 13.5664 14.2422 13.4492 14.4961 13.0195C14.8164 12.5898 14.6992 12.0078 14.2695 11.7188L10.9375 9.5V4.6875C10.9375 4.16797 10.5195 3.75 9.96484 3.75C9.48047 3.75 9.02734 4.16797 9.02734 4.6875L9.0625 10Z"
                fill="#007587"
            />
        </symbol>
        <symbol id="icon-location" viewBox="0 0 20 28">
            <g clipPath="url(#clip0_265_493)">
                <path
                    d="M12.5 10.6666C12.5 12.0468 11.3802 13.1666 10 13.1666C8.61979 13.1666 7.5 12.0468 7.5 10.6666C7.5 9.28642 8.61979 8.16663 10 8.16663C11.3802 8.16663 12.5 9.28642 12.5 10.6666Z"
                    fill="#007587"
                />
                <path
                    opacity="0.4"
                    d="M8.76562 26.6666C6.04688 23.3229 0 15.2187 0 10.6666C0 5.14371 4.47708 0.666626 10 0.666626C15.5208 0.666626 20 5.14371 20 10.6666C20 15.2187 13.9062 23.3229 11.2344 26.6666C10.5938 27.4635 9.40625 27.4635 8.76562 26.6666ZM10 14.8333C12.3021 14.8333 14.1667 12.9687 14.1667 10.6666C14.1667 8.36454 12.3021 6.49996 10 6.49996C7.69792 6.49996 5.83333 8.36454 5.83333 10.6666C5.83333 12.9687 7.69792 14.8333 10 14.8333Z"
                    fill="#007587"
                />
            </g>
            <defs>
                <clipPath id="clip0_265_493">
                    <rect
                        width="20"
                        height="26.6667"
                        fill="white"
                        transform="translate(0 0.666626)"
                    />
                </clipPath>
            </defs>
        </symbol>
        <symbol id="icon-user" viewBox="0 0 11 12">
            <g clipPath="url(#clip0_265_516)">
                <path
                    d="M6.57143 6.78577L5.83259 8.01791L6.57589 10.7835L7.45759 7.18532C9.1808 7.45318 10.5 8.94425 10.5 10.7434C10.5 11.1206 10.192 11.4286 9.81473 11.4286H1.18571C0.80692 11.4286 0.5 11.1206 0.5 10.7434C0.5 8.94425 1.81897 7.45318 3.54241 7.18532L4.42411 10.7835L5.16741 8.01791L4.42857 6.78577H6.57143Z"
                    fill="#007587"
                />
                <path
                    opacity="0.4"
                    d="M2.64282 2.85714C2.64282 1.27924 3.92184 0 5.49997 0C7.07809 0 8.35711 1.27924 8.35711 2.85714C8.35711 4.43527 7.07809 5.71429 5.49997 5.71429C3.92184 5.71429 2.64282 4.43527 2.64282 2.85714Z"
                    fill="#007587"
                />
            </g>
            <defs>
                <clipPath id="clip0_265_516">
                    <rect
                        width="10"
                        height="11.4286"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </symbol>
        <symbol id="icon-mail" viewBox="0 0 14 12">
            <path d="M12.6875 0.903809C13.4121 0.903809 14 1.49143 14 2.21631C14 2.6292 13.8059 3.01748 13.475 3.26631L7.525 7.72881C7.21328 7.96123 6.78672 7.96123 6.475 7.72881L0.525 3.26631C0.194496 3.01748 0 2.6292 0 2.21631C0 1.49143 0.587617 0.903809 1.3125 0.903809H12.6875ZM5.95 8.42881C6.57344 8.89639 7.42656 8.89639 8.05 8.42881L14 3.96631V9.65381C14 10.619 13.2152 11.4038 12.25 11.4038H1.75C0.783398 11.4038 0 10.619 0 9.65381V3.96631L5.95 8.42881Z" />
        </symbol>
        <symbol id="icon-facebook" viewBox="0 0 10 17">
            <path d="M8.72314 9.15381L9.16752 6.25818H6.38908V4.37912C6.38908 3.58693 6.77721 2.81475 8.02158 2.81475H9.28471V0.349434C9.28471 0.349434 8.13846 0.153809 7.04252 0.153809C4.75439 0.153809 3.25877 1.54068 3.25877 4.05131V6.25818H0.715332V9.15381H3.25877V16.1538H6.38908V9.15381H8.72314Z" />
        </symbol>
        <symbol id="icon-calendar" viewBox="0 0 20 23">
            <g clipPath="url(#clip0_245_1391)">
                <path
                    d="M7.14286 2.85714H12.8571V1.42857C12.8571 0.639732 13.4955 0 14.2857 0C15.0759 0 15.7143 0.639732 15.7143 1.42857V2.85714H17.8571C19.0402 2.85714 20 3.81652 20 5V8.57143H0V5C0 3.81652 0.959375 2.85714 2.14286 2.85714H4.28571V1.42857C4.28571 0.639732 4.92411 0 5.71429 0C6.50446 0 7.14286 0.639732 7.14286 1.42857V2.85714Z"
                    fill="#007587"
                />
                <path
                    opacity="0.4"
                    d="M0 8.57153H20V20.7144C20 21.8974 19.0402 22.8572 17.8571 22.8572H2.14286C0.959375 22.8572 0 21.8974 0 20.7144V8.57153ZM2.85714 13.5715C2.85714 13.9644 3.17679 14.2858 3.57143 14.2858H5C5.39286 14.2858 5.71429 13.9644 5.71429 13.5715V12.143C5.71429 11.7501 5.39286 11.4287 5 11.4287H3.57143C3.17679 11.4287 2.85714 11.7501 2.85714 12.143V13.5715ZM9.28571 11.4287C8.89286 11.4287 8.57143 11.7501 8.57143 12.143V13.5715C8.57143 13.9644 8.89286 14.2858 9.28571 14.2858H10.7143C11.1071 14.2858 11.4286 13.9644 11.4286 13.5715V12.143C11.4286 11.7501 11.1071 11.4287 10.7143 11.4287H9.28571ZM14.2857 13.5715C14.2857 13.9644 14.6071 14.2858 15 14.2858H16.4286C16.8214 14.2858 17.1429 13.9644 17.1429 13.5715V12.143C17.1429 11.7501 16.8214 11.4287 16.4286 11.4287H15C14.6071 11.4287 14.2857 11.7501 14.2857 12.143V13.5715ZM3.57143 17.143C3.17679 17.143 2.85714 17.4644 2.85714 17.8572V19.2858C2.85714 19.6787 3.17679 20.0001 3.57143 20.0001H5C5.39286 20.0001 5.71429 19.6787 5.71429 19.2858V17.8572C5.71429 17.4644 5.39286 17.143 5 17.143H3.57143ZM8.57143 19.2858C8.57143 19.6787 8.89286 20.0001 9.28571 20.0001H10.7143C11.1071 20.0001 11.4286 19.6787 11.4286 19.2858V17.8572C11.4286 17.4644 11.1071 17.143 10.7143 17.143H9.28571C8.89286 17.143 8.57143 17.4644 8.57143 17.8572V19.2858ZM15 17.143C14.6071 17.143 14.2857 17.4644 14.2857 17.8572V19.2858C14.2857 19.6787 14.6071 20.0001 15 20.0001H16.4286C16.8214 20.0001 17.1429 19.6787 17.1429 19.2858V17.8572C17.1429 17.4644 16.8214 17.143 16.4286 17.143H15Z"
                    fill="#007587"
                />
            </g>
            <defs>
                <clipPath id="clip0_245_1391">
                    <rect width="20" height="22.8571" fill="white" />
                </clipPath>
            </defs>
        </symbol>
        <symbol id="icon-user-mail" width="15" height="15" viewBox="0 0 15 15">
            <path d="M13.5938 1.875C14.3701 1.875 15 2.50459 15 3.28125C15 3.72363 14.792 4.13965 14.4375 4.40625L8.0625 9.1875C7.72852 9.43652 7.27148 9.43652 6.9375 9.1875L0.5625 4.40625C0.208389 4.13965 0 3.72363 0 3.28125C0 2.50459 0.62959 1.875 1.40625 1.875H13.5938Z" />
            <path
                opacity="0.4"
                d="M0.5625 4.40625L6.9375 9.1875C7.27148 9.43652 7.72852 9.43652 8.0625 9.1875L14.4375 4.40625C14.792 4.13965 15 3.72363 15 3.28125V11.25C15 12.2842 14.1592 13.125 13.125 13.125H1.875C0.839356 13.125 0 12.2842 0 11.25V3.28125C0 3.72363 0.208389 4.13965 0.5625 4.40625Z"
            />
        </symbol>
        <symbol
            id="icon-user-download"
            width="15"
            height="15"
            viewBox="0 0 15 15"
        >
            <g clipPath="url(#clip0_510_996)">
                <path d="M3.08792 7.22461C2.722 6.8584 2.722 6.26514 3.08792 5.89893C3.45413 5.53271 4.04768 5.53271 4.4136 5.89893L6.56252 8.05078V0.9375C6.56252 0.419824 6.98235 0 7.50002 0C8.0177 0 8.43752 0.419824 8.43752 0.9375V8.05078L10.5873 5.90098C10.9532 5.53477 11.5468 5.53477 11.913 5.90098C12.2789 6.26719 12.2789 6.86045 11.913 7.22666L8.16301 10.9767C7.98049 11.1592 7.74026 11.25 7.50002 11.25C7.25979 11.25 7.02014 11.1584 6.83733 10.9753L3.08792 7.22461Z" />
                <path
                    opacity="0.4"
                    d="M14.0625 10.3125H10.1514L8.82568 11.6382C8.47266 11.9912 8.00098 12.1875 7.5 12.1875C6.99902 12.1875 6.52852 11.9925 6.17432 11.6382L4.84863 10.3125H0.9375C0.419824 10.3125 0 10.7323 0 11.25V14.0625C0 14.5802 0.419824 15 0.9375 15H14.0625C14.5802 15 15 14.5802 15 14.0625V11.25C15 10.7314 14.5811 10.3125 14.0625 10.3125ZM12.6562 13.3594C12.2695 13.3594 11.9531 13.043 11.9531 12.6562C11.9531 12.2695 12.2695 11.9531 12.6562 11.9531C13.043 11.9531 13.3594 12.2695 13.3594 12.6562C13.3594 13.043 13.043 13.3594 12.6562 13.3594Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_510_996">
                    <rect width="15" height="15" />
                </clipPath>
            </defs>
        </symbol>
        <symbol id="icon-user-id" width="15" height="15" viewBox="0 0 15 15">
            <path d="M8.63525 5.51338H7.28994V10.0195H8.68066C10.6617 10.0195 11.1158 8.51543 11.1158 7.76631C11.1158 6.54639 10.3383 5.51338 8.63525 5.51338ZM7.5 0.234375C3.48633 0.234375 0.234375 3.48633 0.234375 7.5C0.234375 11.5137 3.48633 14.7656 7.5 14.7656C11.5137 14.7656 14.7656 11.5137 14.7656 7.5C14.7656 3.48633 11.5137 0.234375 7.5 0.234375ZM5.13311 10.8035H4.25889V4.72441H5.13311V10.8035ZM4.696 4.03184C4.5826 4.03184 4.47175 3.99821 4.37747 3.93521C4.28318 3.87221 4.20969 3.78267 4.1663 3.6779C4.1229 3.57314 4.11155 3.45786 4.13367 3.34664C4.1558 3.23543 4.2104 3.13327 4.29058 3.05308C4.37077 2.9729 4.47293 2.9183 4.58414 2.89617C4.69536 2.87405 4.81064 2.8854 4.9154 2.9288C5.02017 2.97219 5.10971 3.04568 5.17271 3.13997C5.23571 3.23425 5.26934 3.3451 5.26934 3.4585C5.2688 3.61039 5.20822 3.75591 5.10081 3.86331C4.99341 3.97072 4.84789 4.0313 4.696 4.03184ZM8.78906 10.8105H6.41602V4.72441H8.77734C11.0253 4.72441 12.0129 6.33076 12.0129 7.76689C12.0117 9.32783 10.7924 10.8105 8.78906 10.8105Z" />
        </symbol>
        <symbol
            id="icon-link-diagrams"
            width="30"
            height="30"
            viewBox="0 0 24 24"
        >
            <path d="M22.5 15C22.5 15.8297 21.8297 16.5 21 16.5C20.1703 16.5 19.5 15.8297 19.5 15V4.5C19.5 3.67172 20.1703 3 21 3C21.8297 3 22.5 3.67172 22.5 4.5V15ZM13.5 15C13.5 15.8297 12.8297 16.5 12 16.5C11.1703 16.5 10.5 15.8297 10.5 15V7.5C10.5 6.67031 11.1703 6 12 6C12.8297 6 13.5 6.67031 13.5 7.5V15ZM9 15C9 15.8297 8.32969 16.5 7.5 16.5C6.67031 16.5 6 15.8297 6 15V12C6 11.1703 6.67031 10.5 7.5 10.5C8.32969 10.5 9 11.1703 9 12V15ZM16.5 9C17.3297 9 18 9.67031 18 10.5V15C18 15.8297 17.3297 16.5 16.5 16.5C15.6703 16.5 15 15.8297 15 15V10.5C15 9.67031 15.6703 9 16.5 9Z" />
            <path
                opacity="0.4"
                d="M1.5 1.5C2.32828 1.5 3 2.17172 3 3V18.75C3 19.1625 3.33562 19.5 3.75 19.5H22.5C23.3297 19.5 24 20.1703 24 21C24 21.8297 23.3297 22.5 22.5 22.5H3.75C1.67906 22.5 0 20.8219 0 18.75V3C0 2.17172 0.671719 1.5 1.5 1.5Z"
            />
        </symbol>
        <symbol id="icon-link-main" width="30" height="30" viewBox="0 0 24 24">
            <g clipPath="url(#clip0_228_1165)">
                <path d="M11.121 0.663463C11.5876 0.223588 12.3751 0.223588 12.8793 0.663463L23.5459 9.99605C24.1001 10.4835 24.1543 11.3252 23.6709 11.8794C23.1835 12.4335 22.3418 12.4877 21.7876 12.0044L12.0001 3.43855L2.21137 12.0044C1.6572 12.4877 0.815117 12.4335 0.330033 11.8794C-0.154883 11.3252 -0.0987166 10.4835 0.455533 9.99605L11.121 0.663463Z" />
                <path
                    opacity="0.4"
                    d="M12 3.43829L21.3375 11.6083L21.3541 19.9958C21.3583 20.9166 20.6125 21.6666 19.6875 21.6666H16.3333C15.4125 21.6666 14.6666 20.9208 14.6666 20V16.3208C14.6666 15.5833 14.0708 14.9875 13.3333 14.9875H10.6666C9.92915 14.9875 9.33331 15.5833 9.33331 16.3208V20C9.33331 20.9208 8.58748 21.6666 7.66665 21.6666H4.33748C3.37915 21.6666 2.6704 20.9208 2.66998 20L2.66748 11.6041L12 3.43829Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_228_1165">
                    <rect
                        width="24"
                        height="21.3333"
                        fill="white"
                        transform="translate(0 0.333313)"
                    />
                </clipPath>
            </defs>
        </symbol>
        <symbol id="icon-link-event" width="30" height="30" viewBox="0 0 21 24">
            <g clipPath="url(#clip0_228_1173)">
                <path d="M6.75 1.5C6.75 0.671719 7.42031 0 8.25 0H12.75C13.5797 0 14.25 0.671719 14.25 1.5C14.25 2.32828 13.5797 3 12.75 3H12V4.61484C11.5125 4.53937 11.0109 4.5 10.5 4.5C9.98906 4.5 9.4875 4.53937 9 4.61484V3H8.25C7.42031 3 6.75 2.32828 6.75 1.5ZM17.6906 5.69063C18.2766 5.10469 19.2234 5.10469 19.8094 5.69063C20.3953 6.27656 20.3953 7.22344 19.8094 7.80938L18.6797 8.94375C18.1313 8.09531 17.4516 7.34062 16.6734 6.70312L17.6906 5.69063ZM11.625 15C11.625 15.6234 11.1234 16.125 10.5 16.125C9.87656 16.125 9.375 15.6234 9.375 15V9C9.375 8.37656 9.87656 7.875 10.5 7.875C11.1234 7.875 11.625 8.37656 11.625 9V15Z" />
                <path
                    opacity="0.4"
                    d="M0.75 14.25C0.75 8.86406 5.11406 4.5 10.5 4.5C15.8859 4.5 20.25 8.86406 20.25 14.25C20.25 19.6359 15.8859 24 10.5 24C5.11406 24 0.75 19.6359 0.75 14.25ZM11.625 9C11.625 8.37656 11.1234 7.875 10.5 7.875C9.87656 7.875 9.375 8.37656 9.375 9V15C9.375 15.6234 9.87656 16.125 10.5 16.125C11.1234 16.125 11.625 15.6234 11.625 15V9Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_228_1173">
                    <rect width="21" height="24" fill="white" />
                </clipPath>
            </defs>
        </symbol>
        <symbol id="icon-link-about" width="30" height="30" viewBox="0 0 24 24">
            <g clipPath="url(#clip0_228_1181)">
                <path d="M12 9C12.8283 9 13.5 8.32828 13.5 7.5C13.5 6.67172 12.8283 6 12 6C11.1717 6 10.5 6.67031 10.5 7.5C10.5 8.32969 11.1703 9 12 9ZM13.875 15.75H13.125V11.625C13.125 11.0062 12.6234 10.5 12 10.5H10.5C9.88125 10.5 9.375 11.0062 9.375 11.625C9.375 12.2438 9.88125 12.75 10.5 12.75H10.875V15.75H10.125C9.50625 15.75 9 16.2563 9 16.875C9 17.4937 9.50625 18 10.125 18H13.875C14.4961 18 15 17.4961 15 16.875C15 16.2539 14.4984 15.75 13.875 15.75Z" />
                <path
                    opacity="0.4"
                    d="M12 0C5.37188 0 0 5.37188 0 12C0 18.6281 5.37188 24 12 24C18.6281 24 24 18.6281 24 12C24 5.37188 18.6281 0 12 0ZM12 6C12.8283 6 13.5 6.67172 13.5 7.5C13.5 8.32828 12.8283 9 12 9C11.1717 9 10.5 8.32969 10.5 7.5C10.5 6.67031 11.1703 6 12 6ZM13.875 18H10.125C9.50625 18 9 17.4984 9 16.875C9 16.2516 9.50391 15.75 10.125 15.75H10.875V12.75H10.5C9.87891 12.75 9.375 12.2461 9.375 11.625C9.375 11.0039 9.88125 10.5 10.5 10.5H12C12.6211 10.5 13.125 11.0039 13.125 11.625V15.75H13.875C14.4961 15.75 15 16.2539 15 16.875C15 17.4961 14.4984 18 13.875 18Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_228_1181">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </symbol>
        <symbol
            id="icon-link-agenda"
            width="30"
            height="30"
            viewBox="0 0 24 22"
        >
            <g clipPath="url(#clip0_228_253)">
                <path d="M20.5499 10.1542C21.1999 9.50416 22.2541 9.50416 22.9082 10.1542L23.5041 10.7542C24.1582 11.4042 24.1582 12.4583 23.5041 13.1125L22.2791 14.3375L19.3249 11.3792L20.5499 10.1542ZM21.3374 15.2792L15.9541 20.6625C15.7832 20.8333 15.5666 20.9542 15.3332 21.0125L12.8291 21.6417C12.5999 21.6958 12.3624 21.6292 12.1957 21.4667C12.0291 21.3 11.9624 21.0583 12.0207 20.7958L12.6457 18.325C12.7041 18.0917 12.8249 17.8792 12.9957 17.6708L18.3791 12.325L21.3374 15.2792Z" />
                <path
                    opacity="0.4"
                    d="M4 1.66666C4 0.930412 4.59583 0.333328 5.33333 0.333328C6.07083 0.333328 6.66667 0.930412 6.66667 1.66666V2.99999H12V1.66666C12 0.930412 12.5958 0.333328 13.3333 0.333328C14.0708 0.333328 14.6667 0.930412 14.6667 1.66666V2.99999H16.6667C17.7708 2.99999 18.6667 3.89541 18.6667 5V7H0V5C0 3.89541 0.895417 2.99999 2 2.99999H4V1.66666ZM18.6667 10.15L12.0542 16.7625C11.7125 17.1042 11.4708 17.5333 11.3542 18.0042L10.725 20.5083C10.6292 20.9 10.6542 21.3 10.7833 21.6667H2C0.895417 21.6667 0 20.7708 0 19.6667V8.33333H18.6667V10.15ZM5 13.6667H11C11.5542 13.6667 12 13.2208 12 12.6667C12 12.1125 11.5542 11.6667 11 11.6667H5C4.44583 11.6667 4 12.1125 4 12.6667C4 13.2208 4.44583 13.6667 5 13.6667ZM5 15.6667C4.44583 15.6667 4 16.1125 4 16.6667C4 17.2208 4.44583 17.6667 5 17.6667H8.33333C8.8875 17.6667 9.33333 17.2208 9.33333 16.6667C9.33333 16.1125 8.8875 15.6667 8.33333 15.6667H5Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_228_253">
                    <rect
                        width="24"
                        height="21.3333"
                        fill="white"
                        transform="translate(0 0.333328)"
                    />
                </clipPath>
            </defs>
        </symbol>
        <symbol
            id="icon-link-speakers"
            width="30"
            height="30"
            viewBox="0 0 22 16"
        >
            <path d="M10.9491 10.5399C7.49909 10.5399 4.58813 11.1037 4.58813 13.2794C4.58813 15.4561 7.51789 16 10.9491 16C14.3991 16 17.31 15.4363 17.31 13.2605C17.31 11.0839 14.3803 10.5399 10.9491 10.5399Z" />
            <path
                opacity="0.4"
                d="M10.949 8.46703C13.2851 8.46703 15.1583 6.58307 15.1583 4.23351C15.1583 1.88306 13.2851 0 10.949 0C8.61293 0 6.73975 1.88306 6.73975 4.23351C6.73975 6.58307 8.61293 8.46703 10.949 8.46703Z"
            />
            <path
                opacity="0.4"
                d="M20.0881 5.21926C20.6925 2.84179 18.9205 0.706573 16.664 0.706573C16.4187 0.706573 16.1841 0.73359 15.9549 0.779519C15.9244 0.786723 15.8904 0.802032 15.8725 0.829049C15.8519 0.86327 15.8671 0.909199 15.8895 0.938917C16.5673 1.89531 16.9568 3.05973 16.9568 4.3097C16.9568 5.50744 16.5996 6.62413 15.9728 7.5508C15.9083 7.64626 15.9656 7.77504 16.0793 7.79485C16.2369 7.82277 16.3981 7.83718 16.5629 7.84168C18.2059 7.88491 19.6807 6.82135 20.0881 5.21926Z"
            />
            <path d="M21.8092 10.8169C21.5083 10.1721 20.7821 9.72997 19.6781 9.51294C19.157 9.38506 17.7467 9.20495 16.435 9.22926C16.4153 9.23196 16.4045 9.24547 16.4027 9.25448C16.4 9.26709 16.4054 9.2887 16.4314 9.30221C17.0376 9.60389 19.3808 10.916 19.0862 13.6834C19.0737 13.8032 19.1695 13.9068 19.2886 13.8887C19.8652 13.8059 21.3489 13.4853 21.8092 12.4866C22.0635 11.9588 22.0635 11.3456 21.8092 10.8169Z" />
            <path
                opacity="0.4"
                d="M6.04483 0.779793C5.8165 0.732964 5.58101 0.706848 5.33567 0.706848C3.07926 0.706848 1.30726 2.84207 1.91255 5.21953C2.31906 6.82162 3.79379 7.88518 5.43685 7.84195C5.60161 7.83745 5.76368 7.82214 5.92037 7.79513C6.03409 7.77531 6.09139 7.64653 6.02692 7.55107C5.40014 6.6235 5.04288 5.50771 5.04288 4.30997C5.04288 3.0591 5.43327 1.89468 6.11109 0.939192C6.13258 0.909473 6.1487 0.863545 6.12721 0.829324C6.1093 0.801407 6.07617 0.786998 6.04483 0.779793Z"
            />
            <path d="M2.32156 9.51267C1.21752 9.7297 0.492248 10.1719 0.191392 10.8167C-0.0637974 11.3453 -0.0637974 11.9586 0.191392 12.4872C0.651629 13.485 2.13531 13.8065 2.71195 13.8885C2.83104 13.9065 2.92595 13.8038 2.91342 13.6831C2.61883 10.9166 4.9621 9.60453 5.56918 9.30284C5.59425 9.28843 5.59962 9.26772 5.59694 9.25421C5.59515 9.2452 5.5853 9.2317 5.5656 9.22989C4.25294 9.20468 2.84358 9.38479 2.32156 9.51267Z" />
        </symbol>
        <symbol
            id="icon-link-committee"
            width="30"
            height="30"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#clip0_228_226)">
                <path d="M12.75 0.777187C12.75 0.354141 13.0359 0 13.5 0C19.2984 0 24 4.70156 24 10.5C24 10.9219 23.6437 11.25 23.2219 11.25H12.75V0.777187Z" />
                <path
                    opacity="0.4"
                    d="M10.5 13.5H21.675C22.1062 13.5 22.4531 13.8656 22.3922 14.2969C21.6422 19.7766 16.9406 24 11.25 24C5.03906 24 0 18.9656 0 12.75C0 7.06406 4.22156 2.35968 9.66094 1.60547C10.1344 1.5464 10.5 1.89187 10.5 2.32547V13.5Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_228_226">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </symbol>
        <symbol
            id="icon-link-dates-news"
            width="30"
            height="30"
            viewBox="0 0 24 28"
        >
            <g clipPath="url(#clip0_228_319)">
                <path d="M8.57143 3.71428H15.4286V2C15.4286 1.05339 16.1946 0.285713 17.1429 0.285713C18.0911 0.285713 18.8571 1.05339 18.8571 2V3.71428H21.4286C22.8482 3.71428 24 4.86553 24 6.28571V10.5714H0V6.28571C0 4.86553 1.15125 3.71428 2.57143 3.71428H5.14286V2C5.14286 1.05339 5.90893 0.285713 6.85714 0.285713C7.80536 0.285713 8.57143 1.05339 8.57143 2V3.71428Z" />
                <path
                    opacity="0.4"
                    d="M0 10.5714H24V25.1429C24 26.5625 22.8482 27.7143 21.4286 27.7143H2.57143C1.15125 27.7143 0 26.5625 0 25.1429V10.5714ZM3.42857 16.5714C3.42857 17.0429 3.81214 17.4286 4.28571 17.4286H6C6.47143 17.4286 6.85714 17.0429 6.85714 16.5714V14.8571C6.85714 14.3857 6.47143 14 6 14H4.28571C3.81214 14 3.42857 14.3857 3.42857 14.8571V16.5714ZM11.1429 14C10.6714 14 10.2857 14.3857 10.2857 14.8571V16.5714C10.2857 17.0429 10.6714 17.4286 11.1429 17.4286H12.8571C13.3286 17.4286 13.7143 17.0429 13.7143 16.5714V14.8571C13.7143 14.3857 13.3286 14 12.8571 14H11.1429ZM17.1429 16.5714C17.1429 17.0429 17.5286 17.4286 18 17.4286H19.7143C20.1857 17.4286 20.5714 17.0429 20.5714 16.5714V14.8571C20.5714 14.3857 20.1857 14 19.7143 14H18C17.5286 14 17.1429 14.3857 17.1429 14.8571V16.5714ZM4.28571 20.8571C3.81214 20.8571 3.42857 21.2429 3.42857 21.7143V23.4286C3.42857 23.9 3.81214 24.2857 4.28571 24.2857H6C6.47143 24.2857 6.85714 23.9 6.85714 23.4286V21.7143C6.85714 21.2429 6.47143 20.8571 6 20.8571H4.28571ZM10.2857 23.4286C10.2857 23.9 10.6714 24.2857 11.1429 24.2857H12.8571C13.3286 24.2857 13.7143 23.9 13.7143 23.4286V21.7143C13.7143 21.2429 13.3286 20.8571 12.8571 20.8571H11.1429C10.6714 20.8571 10.2857 21.2429 10.2857 21.7143V23.4286ZM18 20.8571C17.5286 20.8571 17.1429 21.2429 17.1429 21.7143V23.4286C17.1429 23.9 17.5286 24.2857 18 24.2857H19.7143C20.1857 24.2857 20.5714 23.9 20.5714 23.4286V21.7143C20.5714 21.2429 20.1857 20.8571 19.7143 20.8571H18Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_228_319">
                    <rect
                        width="24"
                        height="27.4286"
                        fill="white"
                        transform="translate(0 0.285713)"
                    />
                </clipPath>
            </defs>
        </symbol>
        <symbol
            id="icon-link-partners"
            width="30"
            height="30"
            viewBox="0 0 24 20"
        >
            <path d="M11.9476 8.0275L10.4626 9.28C10.1245 9.56571 9.71147 9.70487 9.30122 9.70487C8.78972 9.70487 8.28235 9.48824 7.9261 9.06775C7.2826 8.27875 7.3801 7.17625 8.1376 6.535L12.2738 3.12625L10.5338 2.8C8.3851 2.81387 6.3301 3.6805 4.8076 5.19625L3.6001 5.21125V13.5962L5.48447 13.5929L8.87822 16.6611C9.90947 17.4992 11.4188 17.3399 12.2532 16.3144L12.9327 16.8859C13.5282 17.3726 14.4095 17.2789 14.897 16.6845L16.0737 15.237L16.2752 15.4056C16.7909 15.8178 17.5457 15.7428 17.9627 15.2276L18.3204 14.7858C18.7359 14.2725 18.6574 13.5172 18.1444 13.101L11.9476 8.0275ZM20.4001 5.215V13.6C20.4001 14.26 20.9345 14.8019 21.5663 14.8019L24.0001 14.8V5.21875L20.4001 5.215ZM22.2001 13.6C21.872 13.6 21.6001 13.328 21.6001 13.0004C21.6001 12.6678 21.872 12.4007 22.2001 12.4007C22.5282 12.4007 22.8001 12.6679 22.8001 13.0004C22.8001 13.33 22.5301 13.6 22.2001 13.6Z" />
            <path
                opacity="0.4"
                d="M18 5.2075C16.4632 3.67262 15.5775 2.803 13.4025 2.803C13.0091 2.803 12.6551 2.77997 12.27 3.12422L8.1375 6.535C7.38 7.17625 7.2825 8.27875 7.9275 9.07C8.28375 9.49037 8.79112 9.70712 9.30262 9.70712C9.71287 9.70712 10.1257 9.56777 10.464 9.28225L11.9479 8.0275L18.1466 13.1013C18.3102 13.2339 18.4137 13.4077 18.487 13.5903L20.4 13.5663V5.215L18 5.2075ZM0 14.7963L2.4 14.7978C3.06562 14.7978 3.6 14.2615 3.6 13.5966V5.21125H0V14.7963ZM1.8 12.4038C2.12812 12.4038 2.4 12.6709 2.4 13.0034C2.4 13.3309 2.12812 13.603 1.8 13.603C1.47187 13.603 1.2 13.33 1.2 13.0038C1.2 12.67 1.47187 12.4038 1.8 12.4038Z"
            />
        </symbol>
        <symbol
            id="icon-link-participants"
            width="30"
            height="30"
            viewBox="0 0 24 24"
        >
            <path d="M16.8375 14.6625L12 19.5L7.1625 14.6625C3.88453 15.7078 1.5 18.75 1.5 22.3734C1.5 23.2734 2.22797 24 3.12516 24H20.8767C21.7734 24 22.5 23.2734 22.5 22.3734C22.5 18.75 20.1141 15.7078 16.8375 14.6625ZM2.13891 3.73828L2.4375 3.80859V6.55078C2.10938 6.74531 1.875 7.09219 1.875 7.5C1.875 7.89258 2.0918 8.22094 2.39625 8.42578L1.66406 11.3438C1.58203 11.6672 1.76391 12 2.02172 12H3.97875C4.23656 12 4.4182 11.666 4.33617 11.3859L3.60375 8.42813C3.90844 8.22188 4.125 7.89375 4.125 7.5C4.125 7.08984 3.89062 6.74438 3.5625 6.55078V4.08422L6 4.67016L6.00005 7.5H17.9579L18 4.66969L21.8555 3.73828C22.7109 3.5332 22.7109 2.47266 21.8555 2.26172L12.9305 0.105469C12.3211 -0.0351562 11.6827 -0.0351562 11.0728 0.105469L2.13844 2.26172C1.28906 2.46703 1.28906 3.53344 2.13891 3.73828Z" />
            <path
                opacity="0.4"
                d="M18 7.45781C18 10.7742 15.3164 13.4578 12.0422 13.4578C8.76797 13.4578 6 10.8141 6 7.45781H18Z"
            />
        </symbol>
        <symbol
            id="icon-link-pdf-constructor"
            width="30"
            height="30"
            viewBox="0 0 24 32"
        >
            <g clipPath="url(#clip0_228_76)">
                <path d="M14 10V0L24 10H14ZM5.5 14C7.43125 14 9 15.5688 9 17.5C9 19.4312 7.43125 21 5.5 21H5V23C5 23.55 4.5525 24 4 24C3.4475 24 3 23.55 3 23V15C3 14.45 3.4475 14 4 14H5.5ZM7 17.5C7 16.6688 6.33125 16 5.5 16H5V19H5.5C6.33125 19 7 18.3312 7 17.5ZM10 15C10 14.45 10.45 14 11 14H12.5C14.1562 14 15.5 15.3438 15.5 17V21C15.5 22.6562 14.1562 24 12.5 24H11C10.45 24 10 23.55 10 23V15ZM12 22H12.5C13.05 22 13.5 21.55 13.5 21V17C13.5 16.45 13.05 16 12.5 16H12V22ZM21 14C21.55 14 22 14.45 22 15C22 15.55 21.55 16 21 16H19V18H21C21.55 18 22 18.45 22 19C22 19.55 21.55 20 21 20H19V23C19 23.55 18.55 24 18 24C17.45 24 17 23.55 17 23V15C17 14.45 17.45 14 18 14H21Z" />
                <path
                    opacity="0.4"
                    d="M5.5 19H5V16H5.5C6.33125 16 7 16.6688 7 17.5C7 18.3312 6.33125 19 5.5 19ZM12 16H12.5C13.05 16 13.5 16.45 13.5 17V21C13.5 21.55 13.05 22 12.5 22H12V16ZM0 4C0 1.79063 1.79063 0 4 0H14V10H24V28C24 30.2062 22.2062 32 20 32H4C1.79063 32 0 30.2062 0 28V4ZM4 14C3.4475 14 3 14.45 3 15V23C3 23.55 3.4475 24 4 24C4.5525 24 5 23.55 5 23V21H5.5C7.43125 21 9 19.4312 9 17.5C9 15.5688 7.43125 14 5.5 14H4ZM10 23C10 23.55 10.45 24 11 24H12.5C14.1562 24 15.5 22.6562 15.5 21V17C15.5 15.3438 14.1562 14 12.5 14H11C10.45 14 10 14.45 10 15V23ZM18 14C17.45 14 17 14.45 17 15V23C17 23.55 17.45 24 18 24C18.55 24 19 23.55 19 23V20H21C21.55 20 22 19.55 22 19C22 18.45 21.55 18 21 18H19V16H21C21.55 16 22 15.55 22 15C22 14.45 21.55 14 21 14H18Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_228_76">
                    <rect width="24" height="32" fill="white" />
                </clipPath>
            </defs>
        </symbol>
        <symbol
            id="icon-link-background"
            width="30"
            height="30"
            viewBox="0 0 24 22"
        >
            <path d="M20.5542 12.6292L16.1083 5.9625C15.9875 5.77916 15.7792 5.66666 15.5542 5.66666C15.3312 5.66666 15.1229 5.77799 14.9996 5.96354L12.3854 9.88479L11.4542 8.60833C11.3292 8.43333 11.1292 8.33333 10.9167 8.33333C10.7042 8.33333 10.5042 8.43333 10.3792 8.60833L7.4625 12.6083C7.3125 12.8083 7.29167 13.0792 7.40417 13.3042C7.52083 13.525 7.7125 13.6667 8 13.6667H20C20.2458 13.6667 20.4717 13.5314 20.5879 13.3146C20.7042 13.0958 20.6917 12.8333 20.5542 12.6292ZM19 20.3333H5C2.24292 20.3333 0 18.0917 0 15.3333V5.33333C0 4.78333 0.447917 4.33333 1 4.33333C1.55208 4.33333 2 4.78333 2 5.33333V15.3333C2 16.9875 3.34583 18.3333 5 18.3333H19C19.5521 18.3333 20 18.7812 20 19.3333C20 19.8854 19.5542 20.3333 19 20.3333Z" />
            <path
                opacity="0.4"
                d="M21.9625 1.66666H6C4.89583 1.66666 4 2.56207 4 3.66666V14.3333C4 15.4379 4.89542 16.3333 6 16.3333H21.9625C23.1042 16.3333 24 15.4375 24 14.3333V3.66666C24 2.56207 23.1042 1.66666 21.9625 1.66666ZM9.29583 4.33332C10.0325 4.33332 10.6292 4.93041 10.6292 5.66666C10.6292 6.40291 10.0708 6.99999 9.29583 6.99999C8.59583 6.99999 7.9625 6.40416 7.9625 5.66666C7.9625 4.92916 8.59583 4.33332 9.29583 4.33332ZM20.5875 13.3167C20.4708 13.5333 20.2458 13.6667 20 13.6667H8C7.7125 13.6667 7.52083 13.525 7.40417 13.3042C7.29167 13.0792 7.3125 12.8083 7.4625 12.6083L10.3792 8.60832C10.5042 8.43332 10.7042 8.33332 10.9167 8.33332C11.1292 8.33332 11.3292 8.43332 11.4542 8.60832L12.3854 9.88582L14.9996 5.96457C15.0875 5.77916 15.2958 5.66666 15.5542 5.66666C15.777 5.66666 15.985 5.77799 16.1087 5.96353L20.5546 12.6302C20.6917 12.8333 20.7042 13.0958 20.5875 13.3167Z"
            />
        </symbol>

        <symbol id="icon-logout" width="13" height="13" viewBox="0 0 13 13">
            <path d="M12.8172 6.94441L9.15837 10.3975C8.98127 10.5645 8.72165 10.6102 8.49821 10.5142C8.2747 10.4178 8.12979 10.1976 8.12979 9.95431L8.12746 8.14904L4.87746 8.14901C4.4283 8.14901 4.06445 7.78516 4.06445 7.33651V5.71151C4.06445 5.26286 4.42855 4.89901 4.87746 4.89901L8.12746 4.89904L8.12979 3.07091C8.12979 2.82772 8.2747 2.60753 8.49821 2.51105C8.72172 2.41505 8.98139 2.46067 9.15837 2.62769L12.8172 6.08082C13.0609 6.28679 13.0609 6.71335 12.8172 6.94441Z" />
            <path
                opacity="0.4"
                d="M2.4375 12.1875H4.0625C4.51191 12.1875 4.875 11.8244 4.875 11.375C4.875 10.9256 4.51191 10.5625 4.0625 10.5625H2.4375C1.98885 10.5625 1.625 10.1987 1.625 9.75V3.25C1.625 2.80135 1.98885 2.4375 2.4375 2.4375H4.0625C4.51191 2.4375 4.875 2.07365 4.875 1.625C4.875 1.17635 4.51191 0.8125 4.0625 0.8125H2.4375C1.09129 0.8125 0 1.90379 0 3.25V9.75C0 11.0957 1.09129 12.1875 2.4375 12.1875Z"
            />
        </symbol>

        <symbol
            id="icon-arrow-right"
            width="14"
            height="12"
            viewBox="0 0 14 12"
        >
            <path
                d="M12.8125 5.79455L1.5625 5.79456"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.2749 1.27611L12.8124 5.79411L8.2749 10.3129"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </symbol>

        <symbol
            id="icon-pencil"
            // width="20"
            // height="20"
            viewBox="0 0 9 9"
            fill="none"
        >
            <path
                d="M0.639312 6.34397C0.712437 6.09612 0.846559 5.86936 1.02955 5.68655L6.37558 0.339634C6.81504 -0.0997837 7.52871 -0.0997837 7.96816 0.339634L8.66074 1.03274C8.71523 1.08758 8.76269 1.14682 8.80488 1.20922C9.09316 1.64586 9.0457 2.23948 8.66074 2.62444L3.31347 7.96995C3.29062 7.9928 3.26777 7.99983 3.24316 8.03674C3.07441 8.18616 2.87402 8.28108 2.65605 8.36018L0.540875 8.98245C0.392867 9.02639 0.23273 8.98596 0.12357 8.86116C0.0144066 8.76799 -0.0264063 8.60803 0.0171523 8.45862L0.639312 6.34397ZM1.04484 7.95588L2.41875 7.55159C2.45918 7.53928 2.49961 7.50764 2.53828 7.50413L1.90019 7.37581C1.76132 7.34768 1.65181 7.2387 1.62404 7.09983L1.49642 6.46174C1.47691 6.50042 1.46091 6.54084 1.44879 6.58127L1.04484 7.95588ZM3.17636 6.9135L6.73242 3.35745L5.62675 2.25178L2.08652 5.82366L2.25175 6.73245L3.17636 6.9135Z"
                fill="#717171"
            />
        </symbol>

        <symbol
            id="icon-remove"
            // width="20"
            // height="20"
            viewBox="0 0 11 11"
            fill="none"
        >
            <path
                d="M4.16071 8.40178C4.16071 8.58593 4.01004 8.7366 3.82589 8.7366C3.64174 8.7366 3.49107 8.58593 3.49107 8.40178V4.04911C3.49107 3.86495 3.64174 3.71428 3.82589 3.71428C4.01004 3.71428 4.16071 3.86495 4.16071 4.04911V8.40178ZM5.83482 8.40178C5.83482 8.58593 5.68415 8.7366 5.5 8.7366C5.31585 8.7366 5.16518 8.58593 5.16518 8.40178V4.04911C5.16518 3.86495 5.31585 3.71428 5.5 3.71428C5.68415 3.71428 5.83482 3.86495 5.83482 4.04911V8.40178ZM7.50893 8.40178C7.50893 8.58593 7.35826 8.7366 7.17411 8.7366C6.98996 8.7366 6.83929 8.58593 6.83929 8.40178V4.04911C6.83929 3.86495 6.98996 3.71428 7.17411 3.71428C7.35826 3.71428 7.50893 3.86495 7.50893 4.04911V8.40178ZM7.45661 0.553153L8.22461 1.70536H9.68527C9.96359 1.70536 10.1875 1.93031 10.1875 2.20759C10.1875 2.48591 9.96359 2.70982 9.68527 2.70982H9.51786V9.07143C9.51786 9.99637 8.76869 10.7455 7.84375 10.7455H3.15625C2.23172 10.7455 1.48214 9.99637 1.48214 9.07143V2.70982H1.31473C1.03746 2.70982 0.8125 2.48591 0.8125 2.20759C0.8125 1.93031 1.03746 1.70536 1.31473 1.70536H2.77581L3.54339 0.553153C3.76102 0.227058 4.12723 0.03125 4.51855 0.03125H6.48145C6.87277 0.03125 7.23898 0.227079 7.45661 0.553153ZM3.98284 1.70536H7.01716L6.61956 1.11021C6.58817 1.06376 6.53585 1.03571 6.48145 1.03571H4.51855C4.46415 1.03571 4.393 1.06376 4.38044 1.11021L3.98284 1.70536ZM2.48661 9.07143C2.48661 9.44182 2.78648 9.74107 3.15625 9.74107H7.84375C8.21415 9.74107 8.51339 9.44182 8.51339 9.07143V2.70982H2.48661V9.07143Z"
                fill="#717171"
            />
        </symbol>

        <symbol
            id="icon-link-files"
            width="30"
            height="30"
            // fill="none"
            viewBox="0 0 24 28"
        >
            <g clipPath="url(#clip0_450_684)">
                <path d="M18.8571 0.334961V5.45934H24L18.8571 0.334961ZM17.5714 27.665H6.42857C2.88375 27.665 0 24.7932 0 21.2595V6.74043C0 6.03583 0.575893 5.45934 1.28571 5.45934C1.99554 5.45934 2.57143 6.03583 2.57143 6.74043V21.2595C2.57143 23.3786 4.30179 25.1028 6.42857 25.1028H17.5714C18.2812 25.1028 18.8571 25.6766 18.8571 26.3839C18.8571 27.0911 18.2839 27.665 17.5714 27.665Z" />
                <path
                    opacity="0.4"
                    d="M5.14282 19.9784V2.89715C5.14282 1.48207 6.29461 0.334961 7.71425 0.334961H18.8571V5.45934H24V19.9784C24 21.3935 22.8487 22.5406 21.4285 22.5406H7.71425C6.29461 22.5406 5.14282 21.3929 5.14282 19.9784Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_450_684">
                    <rect
                        width="24"
                        height="27.33"
                        fill="white"
                        transform="translate(0 0.334961)"
                    />
                </clipPath>
            </defs>
        </symbol>

        <symbol
            id="icon-search"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            // fill="none"
        >
            <path
                d="M19.6912 18.3984L14.4568 13.1641C15.5897 11.7773 16.2147 10.0312 16.2147 8.125C16.2147 3.63672 12.5768 0 8.08965 0C3.60254 0 -0.000198364 3.63789 -0.000198364 8.125C-0.000198364 12.6121 3.63731 16.25 8.08965 16.25C9.99551 16.25 11.7439 15.5895 13.1287 14.4906L18.3631 19.725C18.5818 19.9102 18.824 20 19.0623 20C19.3006 20 19.542 19.9084 19.7252 19.7254C20.0897 19.3594 20.0897 18.7656 19.6912 18.3984ZM1.87481 8.125C1.87481 4.67891 4.67872 1.875 8.12481 1.875C11.5709 1.875 14.3748 4.67891 14.3748 8.125C14.3748 11.5711 11.5709 14.375 8.12481 14.375C4.67872 14.375 1.87481 11.5703 1.87481 8.125Z"
                fill="white"
            />
        </symbol>
        {/* Добавьте здесь другие символы SVG */}
    </svg>
);

const Icon = ({ name, className, onClick, ...props }) => (
    <svg
        {...props}
        className={`icon ${className ? className : ''}`}
        onClick={onClick}
    >
        <use xlinkHref={`#icon-${name}`} />
    </svg>
);
// const Icon = ({ name, className, onClick }) => (
//   <svg className={`icon ${className}`} onClick={onClick}>
//     <use xlinkHref={`#icon-${name}`} />
//   </svg>
// );

export { IconSprite, Icon };
