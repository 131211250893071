import React, { useState, createContext } from 'react';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
    const [showModal, setShowModal] = useState(false);
    const [html, setHtml] = useState('');
    const [maxWidth, setMaxWidth] = useState();
    const [component, setComponent] = useState(null);
    const [title, setTitle] = useState('Title');
    const [userInfo, setUserInfo] = useState(false);
    const [email, setEmail] = useState('/');
    const [orcid, setOrcid] = useState('/');
    const [file, setFile] = useState('/');
    const [srcImage, setSrcImage] = useState(null);

    const toggleShowModal = () => {
        if (showModal) {
            document.documentElement.classList.remove('lock');
            setShowModal(false);
        } else {
            document.documentElement.classList.add('lock');
            setShowModal(true);
        }
    };

    return (
        <ModalContext.Provider
            value={{
                component,
                setComponent,
                maxWidth,
                setMaxWidth,
                showModal,
                setShowModal,
                html,
                setHtml,
                title,
                setTitle,
                userInfo,
                setUserInfo,
                toggleShowModal,
                email,
                orcid,
                setEmail,
                setOrcid,
                srcImage,
                file,
                setFile,
                setSrcImage,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export { ModalProvider, ModalContext };
