import { configureStore } from '@reduxjs/toolkit';
// import { apiCardsNews } from "../services/api/apiCardsNews";
import { apiAbout } from '../services/api/apiAbout';
import { apiCommittees } from '../services/api/apiCommittees';
import { apiCustomerSatisfactionIndexDiagram } from '../services/api/apiCustomerSatisfactionIndexDiagram';
import { apiEvent } from '../services/api/apiEvent';
import { apiNews } from '../services/api/apiNews';
import { apiLogin } from '../services/api/apiLogin';
import { apiVerifyToken } from '../services/api/apiVerifyToken';
import { apiDiagrams } from '../services/api/apiDiagrams';
import { apiWelcome } from '../services/api/apiWelcome';
import { apiParticipants } from '../services/api/apiParticipants';
import { apiBackground } from '../services/api/apiBackground';
import { apiSpeakers } from '../services/api/apiSpeakers';
import { apiPdftemplates } from '../services/api/apiPdftemplates';
import { apiAgenda } from '../services/api/apiAgenda';

// import { apiUsers } from "../services/api/apiUsers";
// import { apiCategory } from "../services/api/apiCategory";
// const stringMiddleware = () => (next) => (action) => {
// 	if (typeof action === 'string') {
// 		return next({
// 			type: action
// 		})
// 	}

// 	return next(action)
// }

const store = configureStore({
    // reducer: { [apiCardsNews.reducerPath]: apiCardsNews.reducer, [apiCardsProjects.reducerPath]: apiCardsProjects.reducer, [apiUsers.reducerPath]: apiUsers.reducer, [apiCategory.reducerPath]: apiCategory.reducer },
    reducer: {
        [apiAbout.reducerPath]: apiAbout.reducer,
        [apiCommittees.reducerPath]: apiCommittees.reducer,
        [apiCustomerSatisfactionIndexDiagram.reducerPath]:
            apiCustomerSatisfactionIndexDiagram.reducer,
        [apiEvent.reducerPath]: apiEvent.reducer,
        [apiNews.reducerPath]: apiNews.reducer,
        [apiLogin.reducerPath]: apiLogin.reducer,
        [apiVerifyToken.reducerPath]: apiVerifyToken.reducer,
        [apiDiagrams.reducerPath]: apiDiagrams.reducer,
        [apiWelcome.reducerPath]: apiWelcome.reducer,
        [apiParticipants.reducerPath]: apiParticipants.reducer,
        [apiBackground.reducerPath]: apiBackground.reducer,
        [apiSpeakers.reducerPath]: apiSpeakers.reducer,
        [apiPdftemplates.reducerPath]: apiPdftemplates.reducer,
        [apiAgenda.reducerPath]: apiAgenda.reducer,
    },
    // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiCardsNews.middleware, apiCardsProjects.middleware, apiUsers.middleware, apiCategory.middleware),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            apiAbout.middleware,
            apiCommittees.middleware,
            apiCustomerSatisfactionIndexDiagram.middleware,
            apiEvent.middleware,
            apiNews.middleware,
            apiLogin.middleware,
            apiVerifyToken.middleware,
            apiDiagrams.middleware,
            apiWelcome.middleware,
            apiParticipants.middleware,
            apiBackground.middleware,
            apiSpeakers.middleware,
            apiPdftemplates.middleware,
            apiAgenda.middleware
        ),

    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
